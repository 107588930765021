<template>
  <v-container>
    <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>

    <h2>{{ club.club_name }} ({{ club.club_shortcut }})</h2>
    <div class="my-4"><b>Předseda oddílu:</b> <span v-if="club.predseda"><router-link :to="'/ucet/' + club.predseda">{{ club.predseda_name }}</router-link></span> <span v-else>není zadaný</span>  </div>
    <div class="my-2"><b>Počet členů:</b> {{ members.length }}</div>

    <v-row class="d-flex justify-space-between">
      <v-col cols="12" md="2" v-if="isClubManager" >
        <v-btn color="success" text to="/registrace">Registrace člena</v-btn>
      </v-col>
      <v-col cols="12" md="2" v-if="isClubManager">
        <v-btn color="primary" @click="copyEmails">Zkopírovat emaily</v-btn>
        <v-snackbar v-model="snackbarCopyEmails" top color="success" :timeout="3000">
          Emaily byly zkopírovány.
        </v-snackbar>
      </v-col>
      <v-col cols="12" md="2" v-if="isClubManager && hasClubManagerData">
        <v-btn color="primary" @click="submitChanges">Uložit pravomoce</v-btn>
      </v-col>
    </v-row>
    <v-row>
  <v-col cols="12">
    <v-data-table
      :headers="memberHeaders"
      dense
      density="comfortable"
      :items="members"
      item-key="id"
      :loading="loading"
      loading-text="Načítám data..."
      :items-per-page="-1"
      :sort-by="sortBy"
      width="100%"
    >
      <!-- <template v-slot:loading>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </template> -->
      <template v-slot:[`item.surname`]="{ item }">
        <router-link :to="'/ucet/' + item.id" style="white-space: nowrap;">{{ item.surname }} {{ item.first_name }}</router-link>
      </template>
      <!-- <template v-slot:[`item.sex`]="{ item }">
        {{ item.sex === 'M' ? 'Muž' : 'Žena' }}
      </template> -->
      <template v-if="isClubManager" v-slot:[`item.email`]="{ item }">
        {{ item.email }}
      </template>
      <template v-if="isClubManager" v-slot:[`item.si_number`]="{ item }">
        {{ item.si_number }}
      </template>
      <template v-if="isClubManager && hasOrganizerData" v-slot:[`item.organizer`]="{ item }">
        <v-checkbox v-model="item.is_organizer" hide-details></v-checkbox>
      </template>
      <template v-if="isClubManager && hasClubManagerData" v-slot:[`item.club_manager`]="{ item }">
        <v-checkbox
          v-model="item.is_club_manager"
          :disabled="item.id === Number(authStore.userID)"
          hide-details
        ></v-checkbox>
      </template>
      <template v-if="isClubManager && hasClubManagerData" v-slot:[`item.actions`]="{ item }">
        <v-list-item-action v-if="item.id !== Number(authStore.userID)">
          <v-icon @click="openEditDialog(item)" color="blue" size="x-large" class="mr-2" title="editovat údaje člena">mdi-pencil</v-icon>
          <v-icon @click="openRemoveDialog(item)" color="warning" size="x-large" class="mr-2" title="zrušit členství v oddíle">mdi-account-remove</v-icon>
          <v-icon v-if="canDeleteAccount(item)" @click="openDeleteDialog(item)" color="red" size="x-large" title="smazat účet">mdi-delete</v-icon>
        </v-list-item-action>
      </template>
      <template v-slot:no-data>
        <v-alert type="info" class="ma-4">
          Nejsou zaregistrovaní žádní členové
        </v-alert>
      </template>
    </v-data-table>
  </v-col>
</v-row>

                <!-- Dialog pro editaci Account -->
                <v-dialog v-model="dialogEditAccount" persistent max-width="800px">
  <v-card>


      <v-card-title>
        {{ selectedAccount.surname }} {{ selectedAccount.first_name }} ({{ selectedAccount.index }})
      </v-card-title>
      <v-card-text>



        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="selectedAccount.surname" label="Příjmení" required :rules="[rules.surname]"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="selectedAccount.first_name" label="Křestní jméno" required :rules="[rules.firstName]"></v-text-field>
          </v-col>
         <v-col cols="12" md="5">
            <v-text-field v-model="selectedAccount.email" label="Email" :rules="[rules.isValidEmail]" required></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="selectedAccount.si_number" type="number" label="Číslo čipu" :rules="[rules.siNumber]"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="selectedAccount.assist_user" type="number" label="ID správce účtu"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="selectedAccount.born_year" label="Rok narození" type="number" :rules="[rules.year]"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select v-model="selectedAccount.sex" :items="sexOptions" label="Pohlaví" :rules="[rules.sex]"></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="selectedAccount.index" label="Index" required :rules="[rules.index]"></v-text-field>
          </v-col>
        </v-row>





      <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="grey" text @click="dialogEditAccount = false">Zrušit</v-btn>
      <v-btn color="green darken-1" text @click="editAccount" :disabled="!isFormValid">Uložit</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>



          <!-- Dialog pro potvrzení smazání člena oddílu -->
          <v-dialog v-model="dialogRemoveAccount" persistent max-width="600px">
  <v-card>
    <v-card-title class="text-h5">Potvrzení odebrání člena</v-card-title>
    <v-card-text>Opravdu chceš odebrat člena <b>{{ selectedAccount.surname }} {{ selectedAccount.first_name }}</b> z oddílu? Závodníkovi se tak ukončí členství v oddíle.</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="dialogRemoveAccount = false">Zrušit</v-btn>
      <v-btn color="warning darken-1" text @click="removeAccount">Odebrat člena</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>



          <!-- Dialog pro potvrzení smazání Account -->
          <v-dialog v-model="dialogDeleteAccount" persistent max-width="600px">
  <v-card>
    <v-card-title class="text-h5">Potvrzení smazání účtu</v-card-title>
    <v-card-text>Opravdu chceš smazat celý účet uživatele <b>{{ selectedAccount.surname }} {{ selectedAccount.first_name }} {{ selectedAccount.index }}</b>? Tato akce je nevratná a smaže veškerá data uživatele včetně přihlášek na závody, výsledků a finančních záznamů.</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="dialogDeleteAccount = false">Zrušit</v-btn>
      <v-btn color="red darken-1" text @click="deleteAccount">Smazat</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


  </v-container>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import apiClient from '/api.js';
import { useAuthStore } from '/auth.js';

const authStore = useAuthStore();
const loading = ref(true); 


// import { storeToRefs } from 'pinia';

// const { isLoggedIn } = storeToRefs(useAuthStore());
const route = useRoute();
const clubShortcut = ref(route.params.id); // Získání ID oddílu z URL

const errorMessage = ref('');

// Oprávnění
const isClubManager = computed(() => {
  // Ověření role 'club_manager'
  const hasRole = authStore.roles.includes('club_manager');

  // Ujistěte se, že `userClub` a `club.value.id` jsou porovnány jako čísla
  const userClubId = Number(authStore.userClub);
  const currentClubId = Number(club.value.id);

  // Vrátí true pouze pokud uživatel má roli a ID klubu odpovídá
  const indexMatches = userClubId === currentClubId;

  return hasRole && indexMatches;
});



const members = ref([]);
const club = ref({ club_name: '', club_shortcut: '', id: null, predseda: null, predseda_name: '' });
const successMessage = ref('');

// pro zobrazení oprávnění v tabulce
const hasOrganizerData = ref(false);
const hasClubManagerData = ref(false);

const sortBy = [{ key: 'surname', order: 'asc' }]; // Klíč pro řazení

const memberHeaders = computed(() => {
    // Základní hlavičky, které jsou viditelné pro všechny
  let headers = [
    { title: 'Jméno', value: 'surname', sortable: true, align: 'start' },
    { title: 'Index', value: 'index', sortable: true, align: 'start' },
    // { title: 'Pohlaví', value: 'sex', sortable: true, align: 'start' },
  ];
    // Přidáme sloupce pro organizátora a správce oddílu, pokud je uživatel isClubManager
  if (isClubManager.value) {
    headers.push(
        { title: 'Email', value: 'email', sortable: false },
        { title: 'Číslo čipu', value: 'si_number', sortable: false },

      );
      if (hasOrganizerData.value && hasClubManagerData.value) {
        headers.push(
          { title: 'Rok narození', value: 'born_year', sortable: true, align: 'start' },
          { title: 'Organizátor', value: 'organizer', sortable: false },
          { title: 'Správce oddílu', value: 'club_manager', sortable: false },
          { title: 'Akce', value: 'actions', sortable: false, width: '120' }
        );
      }
    else
    headers.push(
      { title: 'Rok narození', value: 'born_year', sortable: true, align: 'start' },
    )

  }
  return headers;
});


const dialogEditAccount = ref(false);
const openEditDialog = (account) => {
  selectedAccount.value = account;
  dialogEditAccount.value = true;
};

const selectedAccount = ref({
  surname: '',
  first_name: '',
  email: '',
  si_number: null,
  born_year: null,
  sex: '',
  index: '',
  assist_user: null,
});

const dialogRemoveAccount = ref(false);
const openRemoveDialog = (account) => {
  selectedAccount.value = account;
  dialogRemoveAccount.value = true;
};


const canDeleteAccount = (item) => {
  const currentDate = new Date();
  const joinedDate = new Date(item.date_joined);
  const diffTime = Math.abs(currentDate - joinedDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= 14;
};

const dialogDeleteAccount = ref(false);
const openDeleteDialog = (account) => {
  selectedAccount.value = account;
  dialogDeleteAccount.value = true;
};


const rules = {
  
  isValidEmail: value => (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) || 'Neplatný email',
  year: value => (value && value >= 1920 && value <= new Date().getFullYear()) || 'Neplatný rok.',
  siNumber: value => !value || /^\d{4,8}$/.test(value) || 'Číslo SI musí mít 4 až 8 cifer.',
  index: value => (/^[A-Za-z]{3}\d{4}$/.test(value)) || 'Neplatný index',
  firstName: value => (value.trim() !== '') || 'Jméno je povinné pole.',
  surname: value => (value.trim() !== '') || 'Příjmení je povinné pole.',
  sex: value => (['M', 'F'].includes(value)) || 'Pohlaví je povinné pole.'

};

// validace pro disable tlačítka uložit v edit account
const isFormValid = computed(() => {
  return rules.isValidEmail(selectedAccount.value.email) === true &&
    rules.year(selectedAccount.value.born_year) === true &&
    rules.siNumber(selectedAccount.value.si_number) === true &&
    rules.index(selectedAccount.value.index) === true &&
    rules.firstName(selectedAccount.value.first_name) === true &&
    rules.surname(selectedAccount.value.surname) === true &&
    rules.sex(selectedAccount.value.sex) === true;
});

const sexOptions = [{title: 'Muž', value: 'M'}, {title: 'Žena', value: 'F'}]

// const countryOptions = [{title: 'Česká republika', value: 'CZE'}] 




// onMounted(() => {
//   fetchClub();
//   fetchMembers();
// });


const fetchClub = async () => {
  try {
    const response = await apiClient.get(`club/?club_shortcut=${clubShortcut.value}`);
      club.value = response.data[0];
  } catch (error) {
    console.error('Error fetching club details:', error);
  }
};


const fetchMembers = async () => {
  try {
    const response = await apiClient.get(`members/?club_shortcut=${clubShortcut.value}`);
    members.value = response.data;
    hasOrganizerData.value = response.data.some(member => member.is_organizer !== undefined);
    hasClubManagerData.value = response.data.some(member => member.is_club_manager !== undefined);
  } catch (error) {
    console.error('Error fetching members:', error);
  } finally {
    loading.value = false;  // Nastavíme loading na false po načtení dat
  }
};

const snackbarCopyEmails = ref(false);  // Pro kontrolu zobrazení snackbaru

const copyEmails = async () => {
   // Filtrování členů, kteří mají email a neobsahují "rob-is.cz"
   const emails = members.value
    .filter(member => member.email && !member.email.includes('rob-is.cz'))  // Filtrovat jen členy, kteří mají email a email neobsahuje "rob-is.cz"
    .map(member => member.email)     // Získat emaily
    .join(', ');                     // Spojit emaily do jednoho řetězce odděleného čárkou

  try {
    // Zkopírování emailů do schránky
    await navigator.clipboard.writeText(emails);
    snackbarCopyEmails.value = true;  // Zobrazit snackbar po úspěchu
  } catch (err) {
    console.error('Chyba při kopírování emailů:', err);
  }
};


const submitChanges = async () => {
  // Tvorba seznamu aktualizovaných členů pro odeslání
  const updatedMembers = members.value
    .filter(member => member.is_organizer === true || member.is_club_manager === true)  // Filtr členů, kteří mají alespoň jednu pravomoc nastavenou na true
    .map(member => {
      const changes = { id: member.id };
      if (member.is_organizer) {
        changes.is_organizer = true;
      }
      if (member.is_club_manager) {
        changes.is_club_manager = true;
      }
      return changes;
    });

  // Kontrolní log výsledného seznamu pro ověření
  console.log('Updating member roles:', JSON.stringify(updatedMembers));

  // Odesílání dat na server
  try {
    const response = await apiClient.post(`members/?club_shortcut=${clubShortcut.value}`, updatedMembers);
    if (response.status === 200) {
      successMessage.value = 'Pravomoce byly úspěšně aktualizovány.';
      authStore.updateRoles(response.data.roles); // uložení rolí uživatele do pinia auth sotre
      await fetchMembers()
    } else {
      throw new Error('Failed to update member roles');
    }
  } catch (error) {
    console.error('Error updating member roles:', error);
  }
};

// Sledujeme změny v parametru ID a reagujeme na ně
watch(() => route.params.id, (newId) => {
  clubShortcut.value = newId; // Aktualizace ref pro clubId
  fetchClub();
  fetchMembers();
}, { immediate: true });




const editAccount = async () => {
  try {
    // Vytvoření jednoduchého objektu z reaktivního selectedAccount
    const accountData = {
      id: selectedAccount.value.id,
      first_name: selectedAccount.value.first_name,
      surname: selectedAccount.value.surname,
      email: selectedAccount.value.email,
      si_number: selectedAccount.value.si_number,
      born_year: selectedAccount.value.born_year,
      sex: selectedAccount.value.sex,
      index: selectedAccount.value.index,
      assist_user: selectedAccount.value.assist_user,
    };

    const response = await apiClient.put(`/members/?account_id=${selectedAccount.value.id}`, accountData);
    if (response.status === 200) {
      successMessage.value = 'Údaje byly úspěšně aktualizovány';
      fetchMembers(); // Aktualizace seznamu členů po úspěšném uložení
      dialogEditAccount.value = false;
      errorMessage.value = ""
      }
    } catch (error) {
    if (error.response && error.response.status === 409) {
      errorMessage.value = error.response.data.error;
    } else {
      errorMessage.value = 'Údaje se nepodařilo uložit: ' + error.message;
    }
    console.error('Error updating account:', error);
  }
};



const removeAccount = async () => {
  try {
    const response = await apiClient.post(`members/?remove_id=${selectedAccount.value.id}`);
    if (response.status === 200) {
      successMessage.value = 'Účet byl úspěšně vyřazen z oddílu';
      fetchMembers(); // Obnovit seznam členů po úspěšném vyřazení
    } else {
      throw new Error('Failed to delete account');
    }
  } catch (error) {
    console.error('Error deleting account:', error);
  } finally {
    dialogRemoveAccount.value = false;
  }
};



const deleteAccount = async () => {
  try {
    const response = await apiClient.delete(`members/?account_id=${selectedAccount.value.id}`);
    if (response.status === 200) {
      successMessage.value = 'Účet byl úspěšně smazán.';
      fetchMembers(); // Obnovit seznam členů po úspěšném smazání
    } else {
      throw new Error('Failed to delete account');
    }
  } catch (error) {
    console.error('Error deleting account:', error);
  } finally {
    dialogDeleteAccount.value = false;
  }
};


</script>

<style scoped>

:deep() .v-table .v-table__wrapper > table > thead > tr > th:not(:last-child) {
    border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
  }
  :deep() .v-table .v-table__wrapper > table > tbody > tr > td:not(:last-child), .v-table .v-table__wrapper > table > tbody > tr > th:not(:last-child) { 
    border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
  }


</style>
