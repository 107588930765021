<template>
    <v-empty-state
      headline="Oh no, 404"
      title="Page not found"
      text="The page you were looking for does not exist"
      :image="imageUrl + 'static/404.jpg'"
      action-text="Zpět na úvodní stranu"
      @click:action="goToHomePage"
    ></v-empty-state>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { ref } from 'vue';

const imageUrl = ref(process.env.VUE_APP_API_STATIC_URL);

const router = useRouter();

function goToHomePage() {
  router.push('/');
}
</script>
