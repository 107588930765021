<template>
  <v-container>
    <v-form @submit.prevent="submitNewPassword" class="form">
      <v-text-field v-model="password" label="Nové heslo" type="password" :rules="[rules.password]" required></v-text-field>
      <v-text-field v-model="confirmPassword" label="Potvrdit nové heslo" type="password" :rules="[() => password === confirmPassword || 'Hesla se musí shodovat']"></v-text-field>

      <v-btn type="submit" color="primary">Nastavit heslo</v-btn>
    </v-form>
  </v-container>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import apiClient from '/api.js';



const password = ref('');
const confirmPassword = ref('');

const router = useRouter();
const route = useRoute();
const uid = route.params.uid;
const token = route.params.token;

const rules = {
  password: value => !value || value.length >= 8 || 'Heslo musí mít alespoň 8 znaků.',
};

const submitNewPassword = () => {
  const url = `password_reset_confirm/${uid}/${token}/`;
  apiClient.post(url, { password: password.value })
    .then(() => {
      localStorage.setItem('successMessage', 'Heslo bylo úspěšně resetováno!');
      // Přesměrujte uživatele na přihlašovací stránku nebo zobrazte potvrzovací stránku
      router.push('/login');
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.error) {
        // Zobrazení chybové zprávy ze serveru
        alert(`Nepodařilo se resetovat heslo: ${error.response.data.error}`);
      }
    });
};



</script>


<style scoped>
.form {
  max-width: 500px;
  padding-top: 40px;
  margin: 0px auto
}
</style>
