import { createApp } from 'vue';
import { createPinia } from 'pinia';  // ukládání tokenů a store dat o uživateli
import App from './App.vue';
import router from './router';

import '@mdi/font/css/materialdesignicons.min.css'


import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labsComponents from 'vuetify/labs/components'

import dateFormatPlugin from './plugins/DateTimeFormat'; // Importuje plugin pro formátování datumu

// Vytvoření instance Pinia
const pinia = createPinia();

const vuetify = createVuetify({
    components: {
        ...components,
        ...labsComponents,
    },
    directives,
})

const app = createApp(App);


app.use(router);
app.use(vuetify);
app.use(pinia);
app.use(dateFormatPlugin);
app.mount('#app');

