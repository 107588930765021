import axios from 'axios';
import router from '/src/router/index.js';
import Cookies from 'js-cookie';

// Získání časové zóny uživatele
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-User-Timezone': userTimeZone
  }
});

// Přidání request interceptoru kvůli tokenům
apiClient.interceptors.request.use(
  config => {
    // Získání tokenu z localStorage
    const authToken = localStorage.getItem('authToken');
    const csrfToken = Cookies.get('csrftoken');  // Získání CSRF tokenu z cookies

    if (authToken) {
      // Přidání Bearer tokenu do hlaviček, pokud existuje
      config.headers.Authorization = `Bearer ${authToken}`;
    } else if (csrfToken) {
      // Přidání CSRF tokenu do hlaviček, pokud není k dispozici Bearer token
      config.headers['X-CSRFToken'] = csrfToken;
    }

    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);


// Přidání response interceptoru k ošetření expirovaných tokenů a autorizačních chyb. Expirované toky se smažou včetně uživ. dat
apiClient.interceptors.response.use(response => response, error => {
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    // Zkontrolujte, jestli není aktuální cesta stránka pro reset hesla nebo login
    const path = router.currentRoute.value.path;
    const basePath = path.split('/')[1]; // Rozdělí cestu a bere první segment, protože jinak to bralo i token a uid
    if (basePath !== 'login' && basePath !== 'reset_hesla') {
      // Smazání uživatelských dat z localStorage
      localStorage.removeItem('authToken');
      localStorage.removeItem('userID');
      localStorage.removeItem('userClub');
      localStorage.removeItem('index');
      localStorage.removeItem('firstName');
      localStorage.removeItem('surname');
      
      // Zobrazit upozornění uživateli
      alert("Tvoje přihlášení vypršelo. Přihlaš se prosím znovu.");
      
      // Přesměrování na přihlašovací stránku nebo zobrazení chyby
      // Zvažte použití Vue Router pro přesměrování, pokud je to možné
      router.go('/login');
      //router.push('/login');
    }
  }
  return Promise.reject(error);
});

export default apiClient;


// místo axiosu se importuje:    import apiClient from '/api.js';
