<template>
<!-- <template v-if="entryClose">
  <v-alert type="error" v-if="entryClose && !isLoading">{{ entryClose }}</v-alert>
</template>
<template v-else> -->
  
  <v-container v-if="!isLoading">
    <v-col cols="12">
      <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
      <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between align-center">
      <h1>Přihláška na {{ event.title }}</h1>
      <v-btn color="primary" text :to="'/soutez/' + event.id" style="height:50px; width:40px;" title="zpět na stránku soutěže"><v-icon size="28">mdi-arrow-left-circle</v-icon></v-btn>
    </v-col>
    <v-col>Datum: {{ $formatDate(event.event_date_start) }} - {{ $formatDate(event.event_date_end) }}</v-col>
    <v-card v-if="Object.keys(allEntries).length">
      <v-card-title v-if="!entryClose">Editace přihlášky</v-card-title>

    <v-card-text>

      <v-row>

        <v-col cols="12" md="3">
        <!-- Rozevírací seznam pro výběr Entry -->
        <v-select
          :items="entryOptions"
          item-title="label"
          item-value="value"
          v-model="selectedEntryId"
          label="Vyber přihlášeného"
          @change="onEntrySelect">
        </v-select>
      </v-col>

      <v-col cols="12" md="2" v-if="mainEntry">
        <v-text-field 
          v-model="mainEntry.si_number" 
          label="Číslo čipu"
          type="number"
          :rules="[rules.siNumber]"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <!-- <div class="d-flex justify-space-between align-center">    Zobrazení ceny pro hlavní kategorii
        <v-card-title>{{ mainRace.title }}</v-card-title>
        <template v-if="selectedCategories[mainRace.id]">
          <span style="color:green">
            {{ mainRace.categories.find(cat => cat.id === selectedCategories[mainRace.id])?.fee }} Kč
          </span>
        </template>
      </div> -->

      <v-col cols="12" md="2" v-if="mainRace">   
      <!-- Výběr kategorie pro Main Entry -->
      <v-tooltip location="top center" origin="auto" text="Slouží k rozřazení mezi přihlášené, je nezávislá na etapách">
        <template v-slot:activator="{ props }">
          <v-select
            v-model="selectedCategories[mainRace.id]"
            :items="filteredMainRaceCategories"
            item-title="name"
            item-value="id"
            label="Vyber hlavní kategorii"
            outlined
            dense
            v-bind="props"
            >
          </v-select>
        </template>
      </v-tooltip>  
    </v-col>

    <v-col cols="12" md="3" v-if="mainEntry">
      <v-text-field 
        v-model="mainEntry.note" 
        label="Poznámka pro pořadatele"
        outlined
        dense
        maxlength="80"
        counter 
      ></v-text-field>
    </v-col>

    <v-col cols="12" md="2" v-if="(mainEntry && siRentData && (siRentData.price !== null || siRentChecked))">
      <v-checkbox
        v-model="siRentChecked"
        label="Půjčit čip"
        dense
      ></v-checkbox>
    </v-col>
  </v-row>

    <!-- Sekce pro Other Entries (ostatní závody) -->
    <v-row justify="center">
      <v-col v-for="race in filteredRaces" :key="race.id" cols="12" md="3" style="background-color:#EDF5FF">
        <v-card class="ma-2">
          <div class="d-flex justify-space-between align-center">
            <v-card-title>{{ race.title }}</v-card-title>
            <template v-if="selectedCategories[race.id]">
              <span style="color:green">
                {{
                  mainEntry && mainEntry.termin_prihl === "2" && race.categories.find(cat => cat.id === selectedCategories[race.id])?.increased_fee 
                  ? race.categories.find(cat => cat.id === selectedCategories[race.id])?.increased_fee 
                  : race.categories.find(cat => cat.id === selectedCategories[race.id])?.fee 
                }} Kč
              </span>
            </template>
          </div>
          <v-card-text>
            <v-select
              v-model="selectedCategories[race.id]"
              :items="race.categories"
              item-title="name"
              item-value="id"
              label="Vyber kategorii"
              outlined
              dense
            >
            </v-select>
            <v-select v-if="event.allow_start_choice" density="comfortable" hide-details="true" :items="startChoices" v-model="selectedStarts[race.id]" label="Požadavek na start"></v-select>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>



    <v-col v-if="!isLoading && Object.keys(accommodationsByDate).length">
            <h2 class="py-3">Ubytování</h2>

            <!-- Nový oddíl pro zobrazení volných míst u všech variant ubytování s limitem -->
            <v-row>
              <v-col v-for="(item, index) in uniqueAccommodations" :key="index">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle :class="{ 'text-red': item.freeSpaces === 0 }">
                  Volná místa: {{ item.freeSpaces }} / {{ item.limit }}
                </v-list-item-subtitle>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2" v-for="(item, date) in accommodationsByDate" :key="'acc-' + date">
                <div class="d-flex justify-space-between">
                  <h3>{{ $formatDate(date) }}</h3>
                  <template v-if="selectedAccommodations[date]">
                    <span v-if="item.length > 1" style="color:green">
                      {{ item.find(acc => acc.id === selectedAccommodations[date])?.price }} Kč
                    </span>
                    <span v-else style="color:green">
                      {{ item[0].price }} Kč
                    </span>
                  </template>
                </div>
                <template v-if="item.length === 1">
                  <v-checkbox
                    v-model="selectedAccommodations[date]"
                    :label="item[0].name"
                    :true-value="item[0].id"
                    :false-value="''"
                    outlined
                    dense
                    clearable
                    @click="onSelectClick(date)"
                  ></v-checkbox>
                </template>
                <template v-else>
                  <v-select
                    :items="item"
                    item-title="name"
                    item-value="id"
                    v-model="selectedAccommodations[date]"
                    label="Vyber ubytování"
                    outlined
                    dense
                    clearable
                    class="truncate"
                    @click="onSelectClick(date)"
                  ></v-select>
                </template>
              </v-col>
            </v-row>
          </v-col>

          <!-- Sekce pro jídlo -->
          <v-col v-if="Object.keys(mealsByDate).length">
            <h2 class="py-3">Jídlo</h2>
            <v-row>
              <v-col cols="12" md="2" v-for="(meals, date) in mealsByDate" :key="'meal-' + date">
                <h3 class="mb-3">{{ $formatDate(date) }}</h3>
                <div v-for="mealType in ['Snídaně', 'Oběd', 'Večeře']" :key="mealType">
                  <v-subheader v-if="meals.filter(meal => meal.type === mealType && meal.price !== '').length">
                    {{ mealType }}
                    <span v-if="selectedMeals[date][mealType]" style="color:green">
                      {{ meals.find(meal => meal.id === selectedMeals[date][mealType]).price }} Kč
                    </span>
                  </v-subheader>
                  <template v-if="meals.filter(meal => meal.type === mealType).length === 1">
                    <v-checkbox
                      v-model="selectedMeals[date][mealType]"
                      :label="meals.find(meal => meal.type === mealType).name"
                      :true-value="meals.find(meal => meal.type === mealType).id"
                      :false-value="null"
                      outlined
                      dense
                      clearable
                    ></v-checkbox>
                  </template>
                  <template v-else>
                    <v-select
                      v-if="meals.some(meal => meal.type === mealType)"
                      :items="meals.filter(meal => meal.type === mealType)"
                      item-title="name"
                      item-value="id"
                      v-model="selectedMeals[date][mealType]"
                      :label="'Vyber ' + mealType"
                      outlined
                      dense
                      clearable
                    ></v-select>
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-row class="d-flex justify-center mt-5" no-gutters>
            <v-col v-if="!entryClose">
              <div class="responsive-container">
                <v-btn @click="submitEntry" color="primary" :disabled="!isEditFormValid" class="responsive-btn">Aktualizovat přihlášku</v-btn>
                <span class="price">Cena: {{ totalSumEdit }} Kč</span>
                <v-btn color="error" @click="openDeleteDialog" class="responsive-btn">Smazat přihlášku</v-btn>
              </div>
            </v-col>
          </v-row>


          <!-- Dialog pro potvrzení smazání přihlášky -->
          <v-dialog v-model="deleteDialog" persistent max-width="600px">
            <v-card>
              <v-card-title class="text-h5">Potvrzení smazání ubytování</v-card-title>
              <v-card-text>Opravdu chceš smazat přihlášku pro <b>{{ currentEntry.mainEntry.first_name }} {{ currentEntry.mainEntry.surname }}</b>? Tato akce je nevratná!</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="deleteDialog = false">Zrušit</v-btn>
                <v-btn color="red darken-1" text @click="deleteEntries">Opravdu smazat přihlášku</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
        </v-card>


  
<br>

  <!-- ******************************************
  ****                                  ******
  ****  Část pro vytvoření přihlášky    ******  
  ****                                  ******  
  ********************************************* -->

  <v-progress-circular v-if="isLoadingCreate && userStore.isLoggedIn" indeterminate color="primary"></v-progress-circular>

  <v-card v-if="!isLoadingCreate && Object.keys(users).length && !entryClose || !userStore.isLoggedIn && !entryClose && !event.auth_entry_only" style="background-color: #EDF5FF;">
    <v-card-title>Vytvoření přihlášky</v-card-title>
    <v-card-text>
    
      <v-form @submit.prevent="submitEntries" v-if="!isLoading">
        <template v-if="userStore.isLoggedIn"> <!-- Pokud je uživatel přihlášený, zobrazí se tento formulář -->
          <v-row>
          <v-col cols="12" md="3">
            <!-- Rozevírací seznam pro výběr uživatele -->
            <v-select
              :items="userOptions"
              item-text="label"
              item-value="value"
              v-model="selectedUserId"
              label="Vyber uživatele"
              @change="onUserSelect">
            </v-select>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="selectedUser.si_number" type="number" label="Číslo čipu" :rules="[rules.siNumber]"></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-tooltip location="top center" origin="auto" text="Slouží k rozřazení mezi přihlášené, je nezávislá na etapách">
              <template v-slot:activator="{ props }">
              <v-select
                :items="race0.categories"
                item-title="name"
                item-value="id"
                v-model="selectedCategoryRace0"
                label="Kategorie pro soutěž"
                @change="onCategoryRace0Select"
                :rules="categoryMain"
                v-bind="props">
              </v-select>
            </template>
          </v-tooltip>  

          </v-col>

          <v-col cols="12" md="3">
            <v-text-field v-model="selectedUser.note" maxlength="80" counter label="Poznámka pro pořadatele"></v-text-field>
          </v-col>

          <v-col cols="12" md="2" v-if="siRentDataInCreateEntry && siRentDataInCreateEntry.price !== null">
            <v-checkbox v-model="selectedUser.si_rent" label="Půjčit čip"></v-checkbox>
          </v-col>
        </v-row>

          <v-row justify="center">
            <v-col v-for="race in races" :key="race.id" cols="12" md="3" style="background-color:#EDF5FF"> <!-- Nastavte šířku sloupce podle počtu závodů, které chcete mít vedle sebe -->
              <v-card class="ma-2">


                <div class="d-flex justify-space-between align-center">
                  <v-card-title>{{ race.title }}</v-card-title>
                  <template v-if="selectedCategoriesForCreateEntry[race.id]">
                    <!-- Určování ceny, kdy se entry_deadline_1 počítá z každého race zvlášť -->
                    <!-- <span style="color:green">
                      {{
                        new Date(race.entry_deadline_1) > new Date()
                          ? race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.fee
                          : race.entry_deadline_2 && new Date(race.entry_deadline_1) < new Date() && new Date(race.entry_deadline_2) > new Date()
                            ? race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.increased_fee
                            : race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.fee
                      }} Kč
                    </span> -->

                    <span style="color:green">
                      {{
                        new Date() < new Date(race0.entry_deadline_1)
                          ? race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.fee
                          : race0.entry_deadline_2 && new Date(race0.entry_deadline_1) < new Date() && new Date() < new Date(race0.entry_deadline_2) && race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.increased_fee
                            ? race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.increased_fee
                            : race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.fee
                      }} Kč
                    </span>
                  </template>
                </div>

                <v-card-text>
                  <v-select
                    :items="race.categories"
                    item-title="name"
                    item-value="id"
                    v-model="selectedCategoriesForCreateEntry[race.id]"
                    label="Vyber kategorii"
                    :key="race.id"
                    clearable
                    density="comfortable"
                  ></v-select>
                  <v-select v-if="event.allow_start_choice" density="comfortable" hide-details="true" :items="startChoices" v-model="selectedStartsEntry[race.id]" label="Požadavek na start"></v-select>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
  
        </template>

        <template v-else>    <!-- Pokud uživatel není v robisu přihlášený, zobrazí se mu tento formulář -->
          <v-row>
            <v-col cols="12" md="2">
            <v-text-field v-model="selectedUser.first_name" label="Jméno" :rules= "requiredField"></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field v-model="selectedUser.surname" label="Příjmení" :rules= "requiredField"></v-text-field>
            </v-col>

            <!-- <v-col cols="12" md="2">
              <v-text-field v-model="selectedUser.index" label="Index"></v-text-field>
            </v-col> -->


            <v-col cols="12" md="2">
              <v-text-field v-model="selectedUser.si_number" type="number" label="Číslo čipu" :rules="[rules.siNumber]"></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field v-model="selectedUser.born_year" label="Rok narození" :rules="[rules.bornYear]"></v-text-field>
            </v-col>


            <v-col cols="12" md="2">
              <v-select v-model="selectedUser.sex" :items="sexOptions" label="Pohlaví"></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-select
                :items="race0.categories"
                item-title="name"
                item-value="id"
                v-model="selectedCategoryRace0"
                label="Kategorie pro soutěž"
                @change="onCategoryRace0Select"
                :rules="categoryMain">
              </v-select>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="selectedUser.email" label="Email" :rules="validEmail"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="selectedUser.note" maxlength="80" counter label="Poznámka pro pořadatele"></v-text-field>
            </v-col>

            <v-col cols="12" md="2" v-if="siRentDataInCreateEntry && siRentDataInCreateEntry.price !== null">
              <v-checkbox v-model="selectedUser.si_rent" label="Půjčit čip"></v-checkbox>
            </v-col>
          </v-row>
          
          <v-row justify="center">
            <v-col v-for="race in races" :key="race.id" cols="12" md="3" style="background-color:#EDF5FF"> 
              <v-card class="ma-2">
              <div class="d-flex justify-space-between align-center">
                <v-card-title>{{ race.title }}</v-card-title>
                <template v-if="selectedCategoriesForCreateEntry[race.id]">
                        
                  <span style="color:green">
                    {{
                      new Date() < new Date(race0.entry_deadline_1)
                        ? race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.fee
                        : race0.entry_deadline_2 && new Date(race0.entry_deadline_1) < new Date() && new Date() < new Date(race0.entry_deadline_2) && race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.increased_fee
                          ? race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.increased_fee
                          : race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry[race.id])?.fee
                    }} Kč
                  </span>
                </template>
              </div>

                <v-card-text>

                  <v-select
                    :items="race.categories"
                    item-title="name"
                    item-value="id"
                    v-model="selectedCategoriesForCreateEntry[race.id]"
                    label="Vyberte kategorii"
                    :key="race.id"
                    clearable
                    density="comfortable"
                  ></v-select>
                  <v-select v-if="event.allow_start_choice" density="comfortable" hide-details="true" :items="startChoices" v-model="selectedStartsEntry[race.id]" label="Požadavek na start"></v-select>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </template>

        <v-col v-if="!isLoading && Object.keys(accommodationsByDate).length">
            <h2 class="py-3">Ubytování</h2>

            <!-- Nový oddíl pro zobrazení volných míst u všech variant ubytování s limitem -->
            <v-row>
              <v-col v-for="(item, index) in uniqueAccommodations" :key="index">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle :class="{ 'text-red': item.freeSpaces === 0 }">
                  Volná místa: {{ item.freeSpaces }} / {{ item.limit }}
                </v-list-item-subtitle>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2" v-for="(item, date) in accommodationsByDate" :key="'acc-' + date">
                <div class="d-flex justify-space-between">
                  <h3>{{ $formatDate(date) }}</h3>
                  <template v-if="selectedAccommodations[date]">
                    <span v-if="item.length > 1" style="color:green">
                      {{ item.find(acc => acc.id === selectedAccommodations[date])?.price }} Kč
                    </span>
                    <span v-else style="color:green">
                      {{ item[0].price }} Kč
                    </span>
                  </template>
                </div>
                <template v-if="item.length === 1">
                  <v-checkbox
                    v-model="selectedAccommodations[date]"
                    :label="item[0].name"
                    :true-value="item[0].id"
                    :false-value="''"
                    outlined
                    dense
                    clearable
                    @click="onSelectClick(date)"
                  ></v-checkbox>
                </template>
                <template v-else>
                  <v-select
                    :items="item"
                    item-title="name"
                    item-value="id"
                    v-model="selectedAccommodations[date]"
                    label="Vyber ubytování"
                    outlined
                    dense
                    clearable
                    class="truncate"
                    @click="onSelectClick(date)"
                  ></v-select>
                </template>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="Object.keys(mealsByDate).length">
            <h2 class="py-3">Jídlo</h2>
            <v-row>
              <v-col cols="12" md="2" v-for="(meals, date) in mealsByDate" :key="'meal-' + date">
                <h3 class="mb-3">{{ $formatDate(date) }}</h3>
                <div v-for="mealType in ['Snídaně', 'Oběd', 'Večeře']" :key="mealType">
                  <template v-if="meals.filter(meal => meal.type === mealType).length === 1">
                    <v-subheader v-if="meals.filter(meal => meal.type === mealType && meal.price !== '').length">
                    {{ mealType }}
                    <span v-if="selectedMeals[date][mealType]" style="color:green">
                      {{ meals.find(meal => meal.id === selectedMeals[date][mealType]).price }} Kč
                    </span>
                  </v-subheader>
                    <v-checkbox
                      v-model="selectedMeals[date][mealType]"
                      :label="meals.find(meal => meal.type === mealType).name"
                      :true-value="meals.find(meal => meal.type === mealType).id"
                      :false-value="null"
                      outlined
                      dense
                      clearable
                    ></v-checkbox>
                  </template>
                  <template v-else>
                    <v-subheader v-if="meals.filter(meal => meal.type === mealType && meal.price !== '').length">
                    {{ mealType }}
                    <span v-if="selectedMeals[date][mealType]" style="color:green">
                      {{ meals.find(meal => meal.id === selectedMeals[date][mealType]).price }} Kč
                    </span>
                  </v-subheader>
                    <v-select
                      v-if="meals.some(meal => meal.type === mealType)"
                      :items="meals.filter(meal => meal.type === mealType)"
                      item-title="name"
                      item-value="id"
                      v-model="selectedMeals[date][mealType]"
                      :label="'Vyber ' + mealType"
                      outlined
                      dense
                      clearable
                    ></v-select>
                  </template>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-btn type="submit" v-if="!loadingSubmitEntry" class="responsive-btn" color="primary" :disabled="!isFormValid">Přihlásit</v-btn> 
          <v-progress-circular v-else indeterminate color="primary"></v-progress-circular> <span class="price">Cena: {{ totalSumCreate }} Kč</span>
      </v-form>
      <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
    </v-card-text>
  </v-card>
  <v-alert type="error" v-if="event.auth_entry_only && !userStore.isLoggedIn">Přihláška na tuto soutěž je povolena pouze pro přihlášené uživatele v robisu.</v-alert>
  </v-container>
  <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
  <!-- </template> -->
</template>




<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import apiClient from '/api.js';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '/auth.js';


const isLoading = ref(true);  // Indikátor načítání
const isLoadingCreate = ref(true);  // Indikátor načítání pro vytvoření přihlášky

const route = useRoute();
const router = useRouter(); // Získání instance routeru

const userStore = useAuthStore();
const eventId = route.params.id;
const event = ref({ entry_is_open: false }); // údaje o eventu
const races = ref([]);
const allRaces = ref([]);
const race0 = ref({});
const users = ref([]);
const selectedUser = ref({ si_number: '', start_group: '', note: '', sex: '', born_year: null });
const selectedUserId = ref(null);
const sexOptions = [{title: 'Muž', value: 'M'}, {title: 'Žena', value: 'F'}];



const entries = ref([]);
const selectedCategories = ref({});
const selectedCategoriesForCreateEntry = ref({});

const selectedCategoryRace0 = ref(null);

const mainEntry = ref(null); // Přidali jsme reaktivní referenci pro mainEntry
const mainRace = computed(() => allRaces.value[0]);
const selectedStarts = ref({});
const selectedStartsEntry = ref({});


const accommodationsByDate = ref({});
const mealsByDate = ref({});
const selectedAccommodations = ref({});
const selectedMeals = ref({});

const deleteDialog = ref(false);

function openDeleteDialog() {
  deleteDialog.value = true;
}



const startChoices = [
      { title: 'Nemám preferenci', value: '' },
      { title: 'První startující', value: '0' },
      { title: 'První skupina', value: '1' },
      { title: 'Druhá skupina', value: '2' },
      { title: 'Třetí skupina', value: '3' },
      { title: 'Poslední startující', value: '4' },
    ];
const allEntries = ref([]);
const selectedEntryId = ref(null);
const currentEntry = ref(null);

const errorMessage = ref('');
const successMessage = ref('');

const rules = {
  siNumber: value => !value || /^\d{4,8}$/.test(value) || 'Číslo SI musí mít 4 až 8 cifer.',
  bornYear: value => { const currentYear = new Date().getFullYear();
    return value >= 1900 && value <= currentYear || `Rok narození musí být mezi 1900 a ${currentYear}.`;}
};


// Výpočetní vlastnost pro kontrolu, zda jsou registrace uzavřené nebo termíny pro race0 vypršely
const entryClose = computed(() => {
  if (isLoading.value) {
    return '';  // Nezobrazujeme žádnou zprávu, dokud se načítají data
  }
  if (!event.value.entry_is_open) {
    return 'Přihlášky včetně editace na událost nejsou povolené.';
  }
  const now = new Date();
  const deadline1 = new Date(race0.value.entry_deadline_1);
  const deadline2 = new Date(race0.value.entry_deadline_2);
  if (now > deadline1 && now > deadline2) {
    return 'Přihlášky jsou uzavřené';
  }
  return '';  // Vrátí prázdný řetězec, pokud neexistuje žádný problém
});



// Vypočítaná vlastnost pro generování možností rozevíracího seznamu Entry
const entryOptions = computed(() => allEntries.value.map(entry => ({
  value: entry.user,
  label: `${entry.mainEntry.surname} ${entry.mainEntry.first_name} (${entry.mainEntry.index})`
})));


const siRentChecked = ref(false); // Místo pro sledování checkboxu


function onEntrySelect(selectedUserId) {
  const selected = allEntries.value.find(entry => entry.user === selectedUserId);
  currentEntry.value = selected;
  mainEntry.value = selected.mainEntry;

  // Zkontrolujeme všechny závody a nastavíme výchozí hodnoty pro start_choice
  allRaces.value.forEach(race => {
    selectedStarts.value[race.id] = '';  // Nastavení defaultní hodnoty pro startovní skupiny
  });

  // Aktualizace selectedCategories na základě kategorií vybraného Entry
  selectedCategories.value = {};
  selected.otherEntries.forEach(oe => {
    selectedStarts.value[oe.race] = oe.start_group;
    selectedCategories.value[oe.race] = oe.category;
  });

  // Nastavte vybranou kategorii pro hlavní závod
  if (selected.mainEntry && selected.mainEntry.race && selected.mainEntry.category) {
    selectedCategories.value[selected.mainEntry.race] = selected.mainEntry.category;
  }

  // Zkontrolujte, zda má uživatel registrovaný si_rent v race_services
  if (siRentData.value && selected.mainEntry.race_service.includes(siRentData.value.id)) {
    siRentChecked.value = true; // Uživatel má přihlášený si_rent
  } else {
    siRentChecked.value = false; // Uživatel nemá přihlášený si_rent
  }

  // Resetování předvybraných hodnot pro ubytování a jídlo
  Object.keys(accommodationsByDate.value).forEach(date => {
    selectedAccommodations.value[date] = null;
  });
  Object.keys(mealsByDate.value).forEach(date => {
    selectedMeals.value[date] = {};
    mealsByDate.value[date].forEach(meal => {
      selectedMeals.value[date][meal.type] = null;
    });
  });



  // Nastavení předvybraných ubytování a jídel podle dat uživatele
  selected.accommodations.forEach(accommodationId => {
    const dateKey = Object.keys(accommodationsByDate.value).find(date => 
      accommodationsByDate.value[date].some(acc => acc.id === accommodationId));
    if (dateKey) {
      selectedAccommodations.value[dateKey] = accommodationId;
    }
  });

  selected.food.forEach(foodId => {
    const dateKey = Object.keys(mealsByDate.value).find(date =>
      mealsByDate.value[date].some(meal => meal.id === foodId));
    if (dateKey) {
      const mealType = mealsByDate.value[dateKey].find(meal => meal.id === foodId).type;
      selectedMeals.value[dateKey][mealType] = foodId;
    }
  });

}




async function fetchRacesAndCategories(eventId) {
  const response = await apiClient.get(`race/?event_id=${eventId}`);
  // Seřadí kategorie každého závodu podle 'name' před uložením do 'races'
  allRaces.value = response.data.map(race => ({
    ...race,
    categories: [
      { id: null, name: "", option: "", fee: 0 }, // Přidá prázdnou možnost
      ...race.categories.sort((a, b) => a.name.localeCompare(b.name)),
    ],
  }));
  
  // Po načtení závodů inicializujte selectedCategories na základě získaných dat
  allRaces.value.forEach(race => {
    const foundEntry = entries.value.find(entry => entry.race === race.id);
    if (foundEntry) {
      selectedCategories.value[race.id] = foundEntry.category;
    }
  });
  // Získání termin_prihl z prvního závodu
  if (allRaces.value.length > 0) {
    mainEntry.value = { termin_prihl: allRaces.value[0].termin_prihl };
  }
}

// Funkce pro načtení Entries
async function fetchEntries() {
  try {
    const response = await apiClient.get(`event/${eventId}/entry/edit/`);
    allEntries.value = response.data;
    // Nastavení prvního Entry jako výchozího vybraného
    if (allEntries.value.length > 0) {
      selectedEntryId.value = allEntries.value[0].user;
      currentEntry.value = allEntries.value[0];

    }
  } catch (error) {
    console.error('Failed to load entries:', error);
    errorMessage.value = 'Nepodařilo se načíst přihlášky.';
  }
}
  


const siRentData = ref(null);

async function fetchRaceServices(eventId) {
  const response = await apiClient.get(`race_service_entry/?event_id=${eventId}`);
  accommodationsByDate.value = response.data.accommodations;
  mealsByDate.value = response.data.foods;

  // Kontrola a nastavení siRentData
  if (response.data.si_rent && response.data.si_rent.length > 0) {
    siRentData.value = response.data.si_rent[0]; // Uložení prvního záznamu si_rent
  } else {
    siRentData.value = null; // Pokud nejsou data pro si_rent, nastavíme null
  }

  // Inicializace výběru ubytování a jídla
  Object.keys(accommodationsByDate.value).forEach(date => {
    selectedAccommodations.value[date] = null;
  });
  Object.keys(mealsByDate.value).forEach(date => {
    selectedMeals.value[date] = {};
    mealsByDate.value[date].forEach(meal => {
      selectedMeals.value[date][meal.type] = null;
    });
  });
}


// Computed property pro získání všech závodů kromě hlavního
const filteredRaces = computed(() => {
  return allRaces.value.slice(1).map(race => {
    const filteredCategories = filterCategoriesByAgeAndSex(
      race.categories, 
      calculateUserAge(mainEntry.value?.born_year), 
      mainEntry.value?.sex
    );
    // Přidání prázdné možnosti do pole kategorií po filtraci
    const categoriesWithEmptyOption = [{ id: null, name: "Nepřihlášen/a", option: "" }, ...filteredCategories];
    
    return {
      ...race,
      categories: categoriesWithEmptyOption,
    };
  });
});

// Vypočítaná vlastnost pro filtrované kategorie hlavního závodu
const filteredMainRaceCategories = computed(() => {
  // Získání hlavního závodu
  const mainRace = allRaces.value[0] || { categories: [] };
  // Filtrace kategorií hlavního závodu
  return filterCategoriesByAgeAndSex(
    mainRace.categories,
    calculateUserAge(mainEntry.value?.born_year),
    mainEntry.value?.sex
  );
});


const calculateUserAge = (birthYear) => new Date().getFullYear() - birthYear;



// Function to filter categories by age and sex
const filterCategoriesByAgeAndSex = (categories, userAge, userSex) => {
  const availableCategories = [];

    // Podmínka pro kategorii MDR
    if (userAge <= 13) {
        availableCategories.push('MDR');
    }
    // Podmínka pro kategorii ostatni
    if (userAge <= 200) {
        availableCategories.push('others');
    }  // pro ostatní kategorie podle typu option
    if (userSex === 'F') {
      if (userAge <= 7) {
        availableCategories.push('MDR', 'W7', 'W9', 'W12', 'W14', 'W16', 'W19', 'W21');
      } else if (userAge <= 9) {
        availableCategories.push('MDR', 'W9', 'W12', 'W14', 'W16', 'W19', 'W21');
      } else if (userAge <= 12) {
        availableCategories.push('MDR', 'W12', 'W14', 'W16', 'W19', 'W21');
      } else if (userAge <= 14) {
        availableCategories.push('W14', 'W16', 'W19', 'W21');
      } else if (userAge <= 16) {
        availableCategories.push('W16', 'W19', 'W21');
      } else if (userAge <= 19) {
        availableCategories.push('W19', 'W21');
      } else if (userAge <= 34) {
        availableCategories.push('W21');
      } else if (userAge <= 44) {
        availableCategories.push('W35', 'W21');
      } else if (userAge <= 54) {
        availableCategories.push('W45', 'W35', 'W21');
      } else if (userAge <= 64) {
        availableCategories.push('W55', 'W45', 'W35', 'W21');
      } else if (userAge <= 99) {
        availableCategories.push('W65', 'W55', 'W45', 'W35', 'W21');
      }
    } else {
      if (userAge <= 12) {
        availableCategories.push('MDR', 'M12', 'M14', 'M16', 'M19', 'M21');
      } else if (userAge <= 14) {
        availableCategories.push('M14', 'M16', 'M19', 'M21');
      } else if (userAge <= 16) {
        availableCategories.push('M16', 'M19', 'M21');
      } else if (userAge <= 19) {
        availableCategories.push('M19', 'M21');
      } else if (userAge <= 39) {
        availableCategories.push('M21');
      } else if (userAge <= 49) {
        availableCategories.push('M40', 'M21');
      } else if (userAge <= 59) {
        availableCategories.push('M50', 'M40', 'M21');
      } else if (userAge <= 69) {
        availableCategories.push('M60', 'M50', 'M40', 'M21');
      } else if (userAge <= 99) {
        availableCategories.push('M70', 'M60', 'M50', 'M40', 'M21');
      }
    }

    // Filtrujeme kategorie na základě dostupných kategorií pro uživatele
    return categories.filter(category => availableCategories.includes(category.option)).sort((a, b) => a.name.localeCompare(b.name));

  };



// sleduje změnu přihlášky z rozevíracího seznamu
watch(() => selectedEntryId.value, (newId) => {
  onEntrySelect(newId);

});


onMounted(async () => {
  try {
    await Promise.all([
      fetchRacesAndCategories(eventId),
      fetchEntries(),
      fetchRaceServices(eventId),
      fetchUsers(eventId),
      fetchRaces(eventId),
      fetchEvent(eventId),
      fetchRaceServicesForCreateEntry(eventId)

    ]);
    
    // Zde nastavte vybrané ubytování a jídlo podle prvního uživatele
    if (allEntries.value.length > 0) {
      onEntrySelect(selectedEntryId.value);
    }
  } catch (error) {
    console.error('Chyba při načítání dat:', error);
  }
});


// Kontrola vyplněných údajů pro přihlášeného uživatele
const isEditFormValid = computed(() => {
  if (mainEntry.value) {
    return rules.siNumber(mainEntry.value.si_number) === true;
  }
  return false;
});


async function submitEntry() {
  const currentSelectedEntry = allEntries.value.find(entry => entry.user === selectedEntryId.value);

  if (!currentSelectedEntry) {
    console.error("Vybrané entry nebylo nalezeno.");
    return;
  }

  // Kontrola, zda je vyplněno si_number nebo zaškrtnutý si_rent
  if (!mainEntry.value.si_number && !siRentChecked.value) {
    errorMessage.value = 'Musíš buď vyplnit číslo čipu, nebo zaškrtnout půjčení čipu!';
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    return; // Ukončení odesílání přihlášky
  }

  let entriesToUpdate = [];

  // Shromáždění ID z ubytování a jídla
  const raceServiceIds = [
    ...Object.values(selectedAccommodations.value).filter(Boolean), // ID ubytování
    ...Object.values(selectedMeals.value).flatMap(mealTypes => Object.values(mealTypes)).filter(Boolean) // ID jídla
  ];

  // Přidání si_rent do race_services, pokud je checkbox zaškrtnutý
  if (siRentChecked.value && siRentData.value) {
    raceServiceIds.push(siRentData.value.id);  // Přidat si_rent ID
  } else {
    // Pokud není zaškrtnutý, odeber si_rent ID z race_services, pokud tam je
    const index = raceServiceIds.indexOf(siRentData.value.id);
    if (index > -1) {
      raceServiceIds.splice(index, 1);
    }
  }
  
  // Přidání hlavního záznamu s aktualizací vybraných dat
  entriesToUpdate.push({
    ...currentSelectedEntry.mainEntry,
    category: selectedCategories.value[currentSelectedEntry.mainEntry.race], // Vybraná kategorie pro hlavní záznam
    id: currentSelectedEntry.mainEntry.id, // ID hlavního záznamu
    race: currentSelectedEntry.mainEntry.race, // ID závodu hlavního záznamu
    si_number: mainEntry.value.si_number === "" ? null : mainEntry.value.si_number,
    note: mainEntry.value.note,
  });

  // Přidání ostatních záznamů s aktualizací vybraných dat
  currentSelectedEntry.otherEntries.forEach(otherEntry => {
    entriesToUpdate.push({
      ...otherEntry,
      category: selectedCategories.value[otherEntry.race] === "" ? null : selectedCategories.value[otherEntry.race],
      id: otherEntry.id, // ID záznamu
      race: otherEntry.race, // ID závodu
      si_number: mainEntry.value.si_number === "" ? null : mainEntry.value.si_number, // Číslo čipu z hlavního záznamu (předpokládáme stejné pro všechny závody)
      start_group: selectedStarts.value[otherEntry.race],
      note: mainEntry.value.note,

    });
  });

  // Kontrola pro závody, které nemají odpovídající entry, ale byla vybrána kategorie
  filteredRaces.value.forEach(race => {
    if (selectedCategories.value[race.id] && !currentSelectedEntry.otherEntries.some(entry => entry.race === race.id)) {
      entriesToUpdate.push({
        id: null,
        race: race.id,
        category: selectedCategories.value[race.id] === "" ? null : selectedCategories.value[race.id],
        si_number: mainEntry.value.si_number === "" ? null : mainEntry.value.si_number,
        start_group: selectedStarts.value[race.id],
      });
    }
    
  });

  let submissionData = {
    role: selectedEntryId.value,
    entries: entriesToUpdate,
    race_services: raceServiceIds,
  };

  try {
    await apiClient.post(`event/${eventId}/entry/edit/`, submissionData);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení úspěšné zprávy
    successMessage.value = 'Přihláška byla aktualizována.';
    errorMessage.value = '';
    await fetchRaceServicesForCreateEntry(eventId);
    await fetchEntries(); // Znovu načtení dat o uživateli
    // Aktualizace údajů uživatele na toho prvního v seznamu
    if (allEntries.value.length > 0) {
      selectedEntryId.value = allEntries.value[0].user;
      onEntrySelect(selectedEntryId.value); // Aktualizace formuláře s nově vybraným uživatelem
    }

  } catch (error) {
    console.error("Chyba při odesílání přihlášky:", error);
    if (error.response) {
      if (error.response.status === 423) {
        errorMessage.value = 'Limit přihlášek pro toto ubytování byl vyčerpán.';
      } else if (error.response.status === 409) {
        errorMessage.value = error.response.data.error;
        await fetchEntries(); // Znovu načtení dat o uživateli
      } else {
        errorMessage.value = 'Přihlášku se nepodařilo aktualizovat.';
      }
    } else {
      errorMessage.value = 'Přihlášku se nepodařilo aktualizovat.';
    }
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    successMessage.value = '';
  }
}

async function deleteEntries() {
 // Najděte vybrané entry pro uživatele
 const selectedUserEntry = allEntries.value.find(entry => entry.user === selectedEntryId.value);
  
  if (!selectedUserEntry) {
    console.error("Entry pro vybraného uživatele nebylo nalezeno.");
    return;
  }

  // Získání ID všech přihlášek (včetně hlavního entry a ostatních entry) pro smazání
  const entryIdsToDelete = [selectedUserEntry.mainEntry.id, ...selectedUserEntry.otherEntries.map(entry => entry.id)];

  // Odeslání požadavku na smazání na server
  try {
    await apiClient.delete(`event/${eventId}/entry/edit/`, {
      data: { entry_id: entryIdsToDelete }
    });
    console.log('Všechny přihlášky pro vybraného uživatele byly úspěšně smazány.');
    deleteDialog.value = false;
    localStorage.setItem('successMessage', 'Přihláška byla úspěšně smazána.');
    router.push(`/soutez/${eventId}`);
    // Zde můžete přidat logiku pro aktualizaci uživatelského rozhraní nebo přesměrování
  } catch (error) {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    errorMessage.value = 'Přihlášku se nepodařilo smazat.';
    console.error("Chyba při mazání přihlášek:", error.response?.data?.error || error.message);
  }
}


///////////////////////////////////////////////////////////////
//
//          Vytvoření přihlášek
//
///////////////////////////////////////////////////////////////


const categoryMain = [v => !!v || 'Musíš vybrat kategorii pro celou soutěž'];
const validEmail = [value => !value || /^[\w-.]+@([\w-]+\.)+[\w-]{2,24}$/.test(value) ? true : 'Neplatný email'];
const requiredField = [(v) => !!v || 'Toto pole je povinné'];


// Zobrazení select pro výběr uživatele
const userOptions = computed(() =>
  users.value.map(user => ({
    title: `${user.surname} ${user.first_name} (${user.index})`,
    value: user.id,
  }))
);

// sledování změn user ID a načítání nových dat o uživately na základě výběru uživatele
watch(selectedUserId, (newValue) => {
    const user = users.value.find(u => u.id === newValue);
    selectedUser.value = user ? user : {};
    selectedCategoryRace0.value = null; // reset kategorií po změně vybraného závodníka
    Object.keys(selectedCategoriesForCreateEntry.value).forEach(key => {
      selectedCategoriesForCreateEntry.value[key] = undefined;
    });
    fetchRaces(eventId);  // Aktualizace kategorií na vybraného uživatele
});

watch(selectedCategoryRace0, (newVal) => {
  // Pokud se změní kategorie v race0, změní se všechny pole kategorie etap podle race0
  const selectedCategoryName = race0.value.categories.find(category => category.id === newVal)?.name;
  if (selectedCategoryName) {
    races.value.forEach(race => {
      const matchingCategory = race.categories.find(category => category.name === selectedCategoryName);
      if (matchingCategory) {
        selectedCategoriesForCreateEntry.value[race.id] = matchingCategory.id;
      }
    });
  }
});

// Sledování změn v roce narození a pohlaví pro nepřihlášené uživatele a načtení kategorií
watch(
  [() => selectedUser.value.born_year, () => selectedUser.value.sex],
  (newValues, oldValues) => {
    // Získání nových hodnot pro born_year a sex
    const [newBornYear, newSex] = newValues;
    // Získání starých hodnot pro born_year a sex
    const [oldBornYear, oldSex] = oldValues;

    // Ověření, že obě nové hodnoty jsou definované a došlo k jejich změně
    if (newBornYear && newSex && (newBornYear !== oldBornYear || newSex !== oldSex)) {
      console.log('Rok narození nebo pohlaví bylo změněno, znovu načítám kategorie.');
      fetchRaces(eventId);  // Aktualizace kategorií na základě věku a pohlaví
    }
  },
  {
    deep: true // Hluboké sledování objektů
  }
);

const fetchUsers = async (eventId) => {
  try {
    const response = await apiClient.get(`event/${eventId}/entry/create/`);
    // Pokud je odpověď pole, použijeme ji přímo, pokud ne, vytvoříme z odpovědi pole
    users.value = Array.isArray(response.data) ? response.data : [response.data];

    // Zkontrolujeme, zda máme alespoň jednoho uživatele, a pokud ano, nastavíme ho jako vybraného
    if (users.value.length > 0) {
      selectedUserId.value = users.value[0].id;
      selectedUser.value = users.value[0];
    }
    isLoadingCreate.value = false;
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};


const fetchRaces = async (eventId) => {
  try {
    const raceResponse = await apiClient.get(`race/?event_id=${eventId}`);
    if (raceResponse.data && raceResponse.data.length > 0) {
      // Najdeme race s nejnižším ID a vypíšeme ho
      const sortedRaces = raceResponse.data.sort((a, b) => a.id - b.id);
      const race0Data = sortedRaces[0];

      // Získání kategorií pro race0
      const categoriesRace0Response = await apiClient.get(`categories/?race=${race0Data.id}`);
      const userAge = calculateUserAge(selectedUser.value.born_year);
      const userSex = selectedUser.value.sex;
      race0.value = {
        ...race0Data,
        categories: filterCategoriesByAgeAndSex(categoriesRace0Response.data, userAge, userSex),
      };

      // Seřadíme zbylé závody podle race_date, odebereme první (neboli ten s nejnižším ID)
      races.value = await Promise.all(sortedRaces.slice(1).sort((a, b) => new Date(a.race_date) - new Date(b.race_date)).map(async (race) => {
        const categoriesResponse = await apiClient.get(`categories/?race=${race.id}`);
        return {
          ...race,
          categories: [{ id: null, name: "Nepřihlásit" }, ...filterCategoriesByAgeAndSex(categoriesResponse.data, userAge, userSex)],
        };
      }));
    }
    isLoading.value = false;
  } catch (error) {
    console.error('There was an error fetching the races or categories:', error);
  }
};

async function fetchEvent(eventId) {
  try {
    const response = await apiClient.get(`event/?id=${eventId}`);
    event.value = response.data[0]; // Předpokládáme, že API vrací pole eventů, vybereme první
  } catch (error) {
    console.error('Nepodařilo se načíst data o eventu:', error);
  }
}




// Kontrola vyplněných údajů pro přihlášeného a nepřihlášeného uživatele
const isFormValid = computed(() => {
  if (!userStore.isLoggedIn) {
    // Zkontrolujte, že všechna pole jsou vyplněna
    return selectedUser.value.first_name &&
           selectedUser.value.surname &&
           selectedUser.value.born_year &&
           selectedUser.value.sex &&
           selectedCategoryRace0.value &&
           validEmailCheck(selectedUser.value.email) &&
           rules.siNumber(selectedUser.value.si_number) === true;
  }
  
  return selectedCategoryRace0.value && rules.siNumber(selectedUser.value.si_number) === true; // přihlášený zkontroluje jen výběr hlavní kategorie a číslo čipu
});

function validEmailCheck(email) {
  return !email || /^[\w-.]+@([\w-]+\.)+[\w-]{2,24}$/.test(email);
}


const siRentDataInCreateEntry = ref(null);

async function fetchRaceServicesForCreateEntry(eventId) {
  try {
    const response = await apiClient.get(`race_service_entry/?event_id=${eventId}`);
    accommodationsByDate.value = response.data.accommodations;
    Object.keys(accommodationsByDate.value).forEach(date => {
      accommodationsByDate.value[date].forEach(accommodation => {
        const limit = accommodation.limit !== null ? accommodation.limit : Infinity;
        const count = accommodation.count !== null ? accommodation.count : 0;
        accommodation.freeSpaces = limit !== Infinity ? limit - count : null;
      });
      selectedAccommodations.value[date] = '';
    });
    mealsByDate.value = response.data.foods;
    Object.keys(mealsByDate.value).forEach(date => {
      selectedMeals.value[date] = {};
      mealsByDate.value[date].forEach(meal => {
        selectedMeals.value[date][meal.type] = null;
      });
    });

    // Kontrola a nastavení siRentDataInCreateEntry
    if (response.data.si_rent && response.data.si_rent.length > 0) {
      siRentDataInCreateEntry.value = response.data.si_rent[0]; // Uložení prvního záznamu si_rent
    }

  } catch (error) {
    console.error('Failed to fetch race services:', error);
  }
}

// Výpočet zbývajících míst ubytování
const uniqueAccommodations = computed(() => {
  const accommodationMap = new Map();
  Object.values(accommodationsByDate.value).forEach(items => {
    items.forEach(item => {
      if (item.limit !== null && !accommodationMap.has(item.name)) {
        accommodationMap.set(item.name, item);
      }
    });
  });
  return Array.from(accommodationMap.values());
});

// Po odeslání se znepřístupní tlačítko, dokud se data nevrátí z backendu
const loadingSubmitEntry = ref(false);

const submitEntries = async () => {
  let raceEntries = [];
  let serviceEntries = [];

  // Příprava základních údajů o uživateli
  const prepareEntry = (entry) => {
    if (entry.si_number === "") {
      entry.si_number = null;
    }
    return entry;
  };

  // Kontrola si_number a si_rent
  if (!selectedUser.value.si_number && !selectedUser.value.si_rent) {
    errorMessage.value = 'Musíš vyplnit Číslo čipu nebo zaškrtnout Půjčení čipu.';
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
    return;
  }

  // spuštění načítání požadavku
  loadingSubmitEntry.value = true;

  // Přidání závodů
  if (selectedCategoryRace0.value) {
    raceEntries.push(prepareEntry({
      race: race0.value.id,
      category: selectedCategoryRace0.value,
      ...selectedUser.value
    }));
  }

  races.value.forEach(race => {
    if (selectedCategoriesForCreateEntry.value[race.id]) {
      raceEntries.push(prepareEntry({
        race: race.id,
        category: selectedCategoriesForCreateEntry.value[race.id],
        start_group: selectedStartsEntry.value[race.id],
        ...selectedUser.value
      }));
    }
  });

  // Přidání výběru ubytování a jídla
  Object.keys(selectedAccommodations.value).forEach(date => {
    if (selectedAccommodations.value[date]) {
      serviceEntries.push(selectedAccommodations.value[date]);
    }
  });

  Object.keys(selectedMeals.value).forEach(date => {
    Object.keys(selectedMeals.value[date]).forEach(mealType => {
      if (selectedMeals.value[date][mealType]) {
        serviceEntries.push(selectedMeals.value[date][mealType]);
      }
    });
  });

  // Pokud je zaškrtnuto půjčení čipu a existuje ID pro si_rent, přidej ho do race_services
  if (selectedUser.value.si_rent && siRentDataInCreateEntry.value) {
    serviceEntries.push(siRentDataInCreateEntry.value.id); // Přidání si_rent ID k race_services
  }



  // Kontrola a odeslání přihlášek
  if (!selectedCategoryRace0.value) {
    alert('Musíte vybrat kategorii pro celou soutěž');
    return;  // Zastavení funkce, pokud není vybraná kategorie pro race0
  }

  if (raceEntries.length > 0) {
    try {
      await apiClient.post(`event/${eventId}/entry/create/`, { races: raceEntries, race_services: serviceEntries });

      // Pokud není uživatel přihlášený, přesměruj ho po úspěšném odeslání na stránku soutěže
      if (!userStore.isLoggedIn) {
        localStorage.setItem('successMessage', 'Přihláška byla úspěšně založena.');
        router.push(`/soutez/${eventId}`);
        return; // Ukončení funkce, aby se nespouštěly další části kódu
      }

      successMessage.value = 'Přihláška byla úspěšně vytvořena.';
      errorMessage.value = '';

      await fetchEntries();
      await fetchUsers((eventId));
      await fetchRaceServicesForCreateEntry(eventId);

      // Aktualizace údajů uživatele na toho prvního v seznamu
      if (allEntries.value.length > 0) {
        selectedEntryId.value = allEntries.value[0].user;
        onEntrySelect(selectedEntryId.value); // Aktualizace formuláře s nově vybraným uživatelem
      }

      window.scrollTo({ top: 0, behavior: 'smooth' });

    } catch (error) {
      console.error('Došlo k chybě při odesílání přihlášek:', error);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
      if (error.response) {
        if (error.response.status === 423) {
          errorMessage.value = 'Limit přihlášek pro toto ubytování byl vyčerpán.';
          successMessage.value = '';
        } else if (error.response.status === 409) {
          errorMessage.value = error.response.data.error;
          successMessage.value = '';
        } else {
          errorMessage.value = 'Přihlášku se nepodařilo vytvořit.';
          successMessage.value = '';
        }
      } else {
        errorMessage.value = 'Přihlášku se nepodařilo vytvořit.';
        successMessage.value = '';
      }
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
      successMessage.value = '';

    } finally {
      loadingSubmitEntry.value = false;  // Ukončení načítání po zpracování odpovědi
    }
  }
};


// CENY   

// Přidání výpočtu celkové sumy pro editaci přihlášky
const totalSumEdit = computed(() => {
  let sum = 0;

  // Funkce pro získání správné ceny podle termin_prihl
  const getCategoryFee = (category, race) => {
    // Hlavní závod (first race) vždy používá fee_event
    if (race.id === mainRace.value.id) {
      return category.fee_event || 0; // Pokud není fee_event dostupné, vrať 0
    }
    // Ostatní závody: používá increased_fee, pokud je termin_prihl "2", jinak fee
    if (mainEntry.value && mainEntry.value.termin_prihl === "2" && category.increased_fee) {
      return category.increased_fee;
    }
    return category.fee || 0; // Pokud není fee dostupné, vrať 0
  };

  // Suma za ubytování
  Object.values(selectedAccommodations.value).forEach(id => {
    if (id) {
      const date = Object.keys(accommodationsByDate.value).find(date =>
        accommodationsByDate.value[date].some(item => item.id === id)
      );
      const accommodation = accommodationsByDate.value[date].find(item => item.id === id);
      sum += accommodation.price;
    }
  });

  // Suma za jídlo
  Object.values(selectedMeals.value).forEach(dayMeals => {
    Object.values(dayMeals).forEach(mealId => {
      if (mealId) {
        const date = Object.keys(mealsByDate.value).find(date =>
          mealsByDate.value[date].some(meal => meal.id === mealId)
        );
        const meal = mealsByDate.value[date].find(meal => meal.id === mealId);
        sum += meal.price;
      }
    });
  });

  // Suma za hlavní závod (vždy započítán)
  if (selectedCategories.value[mainRace.value.id]) {
    const category = mainRace.value.categories.find(cat => cat.id === selectedCategories.value[mainRace.value.id]);
    if (category) {
      sum += getCategoryFee(category, mainRace.value);
    }
  }

  // Suma za kategorie ostatních závodů
  Object.keys(selectedCategories.value).forEach(raceId => {
    if (raceId != mainRace.value.id && selectedCategories.value[raceId]) {
      const race = allRaces.value.find(r => r.id === parseInt(raceId));
      if (race) {
        const category = race.categories.find(cat => cat.id === selectedCategories.value[raceId]);
        if (category) {
          sum += getCategoryFee(category, race);
        }
      }
    }
  });

  // Přidání ceny za si_rent, pokud je checkbox zaškrtnutý
  if (siRentChecked.value && siRentData.value) {
    sum += siRentData.value.price; // Přidání ceny za si_rent
  }


  return sum;
});

// Přidání výpočtu celkové sumy pro vytváření přihlášky
const totalSumCreate = computed(() => {
  let sum = 0;

  // Funkce pro získání správné ceny podle entry_deadline z prvního závodu (race0)
  const getCategoryFee = (category, race) => {
    // Hlavní závod (first race) vždy používá fee_event
    if (race.id === race0.value.id) {
      return category.fee_event || 0; // Pokud není fee_event dostupné, vrať 0
    }

    // Ostatní závody: používá increased_fee, pokud je termín po deadline1, jinak fee
    const now = new Date();
    const deadline1 = new Date(race0.value.entry_deadline_1);
    const deadline2 = new Date(race0.value.entry_deadline_2);

    if (now > deadline1 && (!deadline2 || now < deadline2) && category.increased_fee) {
      return category.increased_fee;
    }
    return category.fee || 0; // Pokud není fee dostupné, vrať 0
  };

  // Suma za ubytování
  Object.values(selectedAccommodations.value).forEach(id => {
    if (id) {
      const date = Object.keys(accommodationsByDate.value).find(date =>
        accommodationsByDate.value[date].some(item => item.id === id)
      );
      const accommodation = accommodationsByDate.value[date].find(item => item.id === id);
      sum += accommodation.price;
    }
  });

  // Suma za jídlo
  Object.values(selectedMeals.value).forEach(dayMeals => {
    Object.values(dayMeals).forEach(mealId => {
      if (mealId) {
        const date = Object.keys(mealsByDate.value).find(date =>
          mealsByDate.value[date].some(meal => meal.id === mealId)
        );
        const meal = mealsByDate.value[date].find(meal => meal.id === mealId);
        sum += meal.price;
      }
    });
  });

   // Přidání ceny za půjčení čipu, pokud je vybráno a existuje si_rent
   if (selectedUser.value.si_rent && siRentDataInCreateEntry.value) {
    sum += siRentDataInCreateEntry.value.price;
  }

  // Suma za hlavní závod (vždy započítán)
  if (selectedCategoryRace0.value) {
    const category = race0.value.categories.find(cat => cat.id === selectedCategoryRace0.value);
    if (category) {
      sum += getCategoryFee(category, race0.value);
    }
  }

  // Suma za kategorie ostatních závodů
  Object.keys(selectedCategoriesForCreateEntry.value).forEach(raceId => {
    if (raceId != race0.value.id && selectedCategoriesForCreateEntry.value[raceId]) {
      const race = races.value.find(r => r.id === parseInt(raceId));
      if (race) {
        const category = race.categories.find(cat => cat.id === selectedCategoriesForCreateEntry.value[raceId]);
        if (category) {
          sum += getCategoryFee(category, race);
        }
      }
    }
  });
  

  return sum;
});



let isSelectClicked = ref(false); // Příznak pro kontrolu kliknutí

// Funkce pro zachycení kliknutí na select
function onSelectClick(date) {
  console.log(`Došlo ke kliknutí na select pro datum ${date}`);
  isSelectClicked.value = true; // Aktivuje watcher při kliknutí
}

// Watcher sleduje změny a aplikuje je jen tehdy, když je select kliknutý
watch(() => JSON.parse(JSON.stringify(selectedAccommodations.value)), (newVal, oldVal) => {
  if (!isSelectClicked.value) {
    console.log("Změny ignorovány, nebylo kliknuto na select.");
    return;
  }

  console.log("Aktuální selectedAccommodations:", newVal);
  let changedDate = null;

  // Projdeme všechny datumy a zjistíme, kde došlo ke změně varianty
  Object.keys(newVal).forEach(date => {
    const newAccommodationId = newVal[date];
    const oldAccommodationId = oldVal ? oldVal[date] : null;

    // Pokud se hodnota změnila, zjistíme variantu
    if (newAccommodationId !== oldAccommodationId) {
      changedDate = date;
      console.log(`Změněná hodnota pro datum: ${date}, Nové ID: ${newAccommodationId}, Staré ID: ${oldAccommodationId}`);
      
      const selectedAccommodation = accommodationsByDate.value[date].find(acc => acc.id === newAccommodationId);
      if (selectedAccommodation) {
        const selectedVariant = selectedAccommodation.variant;
        console.log(`Vybraná varianta pro datum ${date}: ${selectedVariant}`);

        // Projdeme ostatní datumy a nastavíme stejnou variantu, pokud existuje
        Object.keys(accommodationsByDate.value).forEach((otherDate) => {
          // Neprovádíme změnu na poli, kde došlo ke změně, ani tam, kde je pouze jedna možnost
          if (otherDate !== changedDate && accommodationsByDate.value[otherDate].length > 1) {
            const accommodationWithSameVariant = accommodationsByDate.value[otherDate].find(
              (acc) => acc.variant === selectedVariant
            );

            // Pokud existuje ubytování se stejnou variantou, nastavíme ho
            if (accommodationWithSameVariant) {
              console.log(`Nastavuji variantu ${selectedVariant} pro datum ${otherDate}`);
              selectedAccommodations.value[otherDate] = accommodationWithSameVariant.id;
            } else {
              console.log(`Nenalezeno ubytování s variantou ${selectedVariant} pro datum ${otherDate}`);
            }
          } else if (accommodationsByDate.value[otherDate].length === 1) {
            console.log(`Datum ${otherDate} má pouze jednu možnost, změny se neprovádějí.`);
          }
        });
      } else {
        console.log(`Ubytování s ID ${newAccommodationId} nebylo nalezeno pro datum ${changedDate}`);
      }
    }
  });

  isSelectClicked.value = false; // Resetuje příznak po dokončení změn
}, { deep: true });
















</script>


<style scoped>


.text-red {
  color: red;
}


@media (max-width: 600px) {
  .responsive-btn {
    width: 100%;
    margin-bottom: 10px;
  }
  .responsive-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .price {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    color: red;
    display: block;
    font-weight: bold;
  }
}
@media (min-width: 601px) {
  .responsive-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price {
    color: red;
    margin-left: 10px;
    margin-right: auto;
    font-weight: bold;

  }
}



</style>