<template>
  <v-container>
    <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
    <v-form @submit.prevent="submitEvent">
      <v-col cols="12" sm="6" md="6" lg="6" style="margin: 0px auto">
        <h2>Vytvoření soutěže</h2>
        <v-text-field v-model="eventForm.title" label="Název soutěže" :rules="titleRule"></v-text-field>
        <v-text-field v-model="eventForm.event_date_start" label="Datum začátku" type="date" required></v-text-field>
        <v-text-field v-model="eventForm.event_date_end" label="Datum konce" type="date" required></v-text-field>
        <v-select v-model="eventForm.event_type" :items="eventTypes" label="Typ události" :rules="eventType"></v-select>
        <v-tooltip location="top center" origin="auto" text="Viditelnost určuje zobrazování v kalendáři a na profilu závodníků, pokud jsou na soutěž a etapy přihlášení">
          <template v-slot:activator="{ props }">
            <v-select v-bind="props" v-model="eventForm.visibility" :items="visibilityTypes" label="Viditelnost" :rules="visibilityType"></v-select>
          </template>
        </v-tooltip>
        <v-btn color="success" type="submit" style="float: right;">Vytvořit soutěž</v-btn>
        <v-btn color="primary" @click="addRace">Přidat etapu</v-btn>

      </v-col>

      <v-row>
      <v-col cols="12" sm="6" md="4" lg="3" v-for="(race, index) in races" :key="index">
        <v-card>
          <v-card-title>
            Etapa #{{ index + 1 }}
            <v-btn small color="error" @click="removeRace(index)" style="float: right;">Odstranit</v-btn>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="race.title" label="Název etapy" :rules="titleRule"></v-text-field>
            <v-text-field v-model="race.race_date" label="Datum závodu" type="date" required></v-text-field>
            <v-select v-model="race.race_type" :items="raceTypes" label="Typ závodu" :rules="eventType"></v-select>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    </v-form>
    
  </v-container>
</template>


<script setup>
import { ref, reactive } from 'vue';
import apiClient from '/api.js';
import { useAuthStore } from '/auth.js';
import { useRouter } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore();

const titleRule = ref([v => !!v || 'Pojmenuj soutěž']);

const eventForm = reactive({
  title: '',
  event_date_start: '',
  event_date_end: '',
  event_type: '',
  visibility: '',
});

const eventType = ref([v => !!v || 'Vyber typ']);

const eventTypes = [
  { title: 'Závody', value: 'races' },
  { title: 'Soustředění', value: 'camp' },
  { title: 'Ostatní', value: 'other' },

  // { title: 'Reprezentační', value: 'repre' },
  // { title: 'SCM', value: 'scm' },
  // { title: 'Asociační', value: 'association' },
];

const visibilityType = ref([v => !!v || 'Vyber viditelnost v kalendáři']);

const visibilityTypes = ref([
  { title: 'Veřejný', value: 'public' },
  { title: 'Pouze oddíl', value: 'club' },
  ]);


const races = ref([]);
const raceTypes = [
{ title: 'Trénink', value: 'training' },
  { title: 'Soutěž III. stupně', value: '3st' },
  { title: 'Soutěž II. stupně', value: '2st' },
  { title: 'MČR/NŽ - I. stupeň', value: 'mcr-nz' },
//  { title: 'Štafety', value: 'relay' },
  { title: 'Ostatní', value: 'other' },
  // { title: 'Reprezentační akce', value: 'repre' },
  // { title: 'ŽDR akce', value: 'scm' },
  // { title: 'Asociační', value: 'association' },
];

const errorMessage = ref('');

function addRace() {
  races.value.push({ title: '', race_date: '', race_type: '' });
}

function removeRace(index) {
  races.value.splice(index, 1);
}

async function submitEvent() {
  errorMessage.value = ''; // Reset error message před voláním API

  const startDate = new Date(eventForm.event_date_start);
  const endDate = new Date(eventForm.event_date_end);

   // Kontrola, zda datum začátku je před datem konce události
  if (startDate > endDate) {
    errorMessage.value = 'Datum začátku soutěže musí být před datem konce soutěže.';
    return;
  }

  // Kontrola, zda je vybrán první typ eventu a vytváří se nějaké závody
  if (eventForm.event_type === 'races' && races.value.length === 0) {
    errorMessage.value = 'Pokud vytváříš závod, musíš přidat alespoň jednu etapu.';
    return;
  }

  // Kontrola, zda datum závodu je v rozmezí dat začátku a konce události
  for (let race of races.value) {
    const raceDate = new Date(race.race_date);
    if (raceDate < startDate || raceDate > endDate) {
      errorMessage.value = 'Datum etapy musí být v rozmezí konání soutěže.';
      return;
    }
  }

  const autoCreatedRace = {
    title: eventForm.title, // Použijte název události jako název závodu
    race_date: eventForm.event_date_start, // Použijte datum začátku události jako datum závodu
    race_type: "other", // Můžete nastavit typ závodu stejně jako typ události nebo jinou logiku
  };

  try {
    const eventData = {
      ...eventForm,
      club: authStore.userClub, // Přidání ID klubu do dat eventu
      races: [autoCreatedRace, ...races.value],
    };
    const response = await apiClient.post('event/create/', eventData);
    console.log(response.data);
    localStorage.setItem('successMessage', 'Soutěž byla úspěšně vytvořena.'); // Uložení alertu do local store
    // Přesměrovat uživatele na stránku s detaily nově vytvořené události
    if (response.data && response.data.id) {
      router.push(`/soutez/${response.data.id}`);
    }
  } catch (error) {
    errorMessage.value = "Vytvoření soutěže se nezdařilo. Zkontroluj, jestli jsi vyplnil všechna pole."; // Nastavení error message
    console.error(error);
  }
}
</script>