<template>
  <v-container>
    <v-alert type="error" v-if="loginError">{{ loginError }}</v-alert>
    <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>


    <!-- Formulář pro přihlášení -->
    <v-form v-if="showLoginForm" @submit.prevent="login" class="form">
      <h2>Přihlášení</h2>
      <v-text-field v-model="email" type="email" label="Email" required></v-text-field>
      <v-text-field 
        v-model="password" 
        :type="visible ? 'text' : 'password'"
        label="Heslo" 
        required 
        autocomplete="current-password"
        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append-inner="visible = !visible">
      </v-text-field>     
      <div class="d-flex justify-space-between align-center">
        <v-btn v-if="!isLoading" type="submit" color="primary">Přihlásit se</v-btn>
        <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
        <v-btn text @click="toggleForm">Zapomněl/a jsem heslo</v-btn>
      </div>
    </v-form>

    <!-- Formulář pro obnovu hesla -->
    <v-form v-else @submit.prevent="resetPassword" class="form">
      <h2>Resetování hesla</h2>

      <v-text-field v-model="resetEmail" label="Email" required :rules="emailRules"></v-text-field>
      <div class="d-flex justify-space-between align-center">
        <v-btn :disabled="!isEmailValid" type="submit" color="primary">Resetovat heslo</v-btn>
        <v-btn text @click="toggleForm">Už jsem si vzpomněl/a</v-btn>
      </div>
    </v-form>
  </v-container>
</template>




<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router'; 
import { useAuthStore } from '/auth.js';
import apiClient from '/api.js';

const email = ref('');
const password = ref('');
const loginError = ref(''); 
const successMessage = ref(''); 
const isLoading = ref(false); 

const authStore = useAuthStore();
const router = useRouter();

const resetEmail = ref('');
const showLoginForm = ref(true);


// Stav pro viditelnost hesla
const visible = ref(false);


// pravidlo pro tlačítko
const isEmailValid = computed(() => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(resetEmail.value);
});

// pravidlo pro pole email
const emailRules = [
  v => !!v || 'Email je povinný.', // Kontrola, zda je pole vyplněno
  v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Neplatný formát emailu.' // Kontrola formátu emailu
];

const toggleForm = () => {
  showLoginForm.value = !showLoginForm.value; // Přepne zobrazení formulářů
  loginError.value = ''; // Resetuje chybové hlášení
};

onMounted(() => {
  // načtení zprávy po úspěšné změně hesla
  const message = localStorage.getItem('successMessage');
  if (message) {
    successMessage.value = message;
    localStorage.removeItem('successMessage');
  }
});


const login = async () => {
  loginError.value = ''; // Resetujte chybu před pokusem o přihlášení
  isLoading.value = true;
  try {
    await authStore.login(email.value, password.value);
    router.push('/');
    // Přesměrujte uživatele na domovskou stránku nebo jinam po úspěšném přihlášení
  } catch (error) {
    loginError.value = 'Přihlášení selhalo. Zkontrolujte své přihlašovací údaje.'; // Nastavte obecnou chybovou zprávu
    if (error.response && error.response.data) {
      // Pokud server vrátí specifickou chybovou zprávu, zobrazte ji
      loginError.value = error.response.data.detail || 'Došlo k chybě. Zkuste to prosím znovu.';
    }
  }
  finally {
    isLoading.value = false; // Nastavte stav načítání zpět na false
  }
};

const resetPassword = async () => {
  try {
    await apiClient.post('password_reset/', { email: resetEmail.value });
    resetEmail.value = '';
    showLoginForm.value = true;
    successMessage.value = 'Pokud zadaný email v ROBisu existuje, zašleme na něj instrukce k resetu hesla.';
    // Po úspěšném odeslání resetu můžete přepnout formulář nebo jinak reagovat
  } catch (error) {
    if (error.response && error.response.data) {
      loginError.value = error.response.data.detail || 'Došlo k chybě. Zkuste to prosím znovu.';
    }
  }
};

</script>


<style scoped>
.form {
  max-width: 600px;
  padding-top: 40px;
  margin: 0px auto
}
</style>
